<template>
  <div class="examPaperContent">
    <van-tabs v-model="active" color="#1A7EE2" title-active-color="#1A7EE2" @click="onClick">
      <van-tab v-for="(item, index) in tabList" :key="index" :title="item.title">
        <paper
          v-if="active == index"
          :ref="`paper${index}`"
          :headTitle='headTitle'
          :questionList='questionList'
          :remainTime='remainTime'
          :sheetBtnName='sheetBtnName'
          :isCollect='queryObj.type'
          @saveAnswer='saveAnswer'
          @submit='submit'
          @handcollect='handcollect'
          @updateScore='updateScore'
          @changeRemarkRead='changeRemarkRead'
          @changeQuestionType='changeQuestionType'
          @nextEvent='nextEvent'
          @getNewData='getPaperList'
        ></paper>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import paper from '../paper'
import { Toast, Dialog, Notify } from "vant";
import _ from "lodash";
export default {
  name: 'collectPaper',
  components: {
    paper
  },
  data () {
    return {
      questionList: [],
      headTitle: '本地测试',
      sheetBtnName: ['提交'],
      remainTime: '',
      // queryObj: {
      //   type: 0, // 考试类型
      //   status: 2, // 考试状态
      //   courseId: null, // 收藏需要用
      //   paperId: 14215 // 试卷id
      //   // paperId: 15958 // 试卷id
      //   // paperId: 14093 // 试卷id
      // },
      queryObj: {
        courseId: 1221,
        page: 1,
        pageSize: 30,
        questionType: 1,
        source: 2,
        type: 2 // 1:收藏 2:错题
      },
      tabList: [
        {
          title: '专项练习',
          source: 2
        },
        {
          title: '真题精编',
          source: 0
        },
        {
          title: '模拟考试',
          source: 1
        }
      ],
      active: 0
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      const type = this.$store.state.deviceType
      if (val && type === 1) {
        // ios
        window.webkit.messageHandlers.getData.postMessage('examQuery')
        window.getIosQuery = this.getIosQuery
      } else if (val && type === 2) {
        // 安卓
        this.getAnQuery()
      } else {
        // 本地
        this.getPaperList()
      }
    }
  },
  computed: {
  },
  created () {
    this.queryObj.pageSize = this.$store.state.pageSize
    // this.getPaperList()
  },
  methods: {
    // ios获取参数
    getIosQuery (query) {
      let data = JSON.parse(query)
      Object.assign(this.queryObj, data)
      this.getPaperList()
    },
    // 安卓获取参数
    getAnQuery () {
      const AnQuery = window.Android.getCourseId()
      const AnType = window.Android.getType()
      this.queryObj.courseId = AnQuery
      this.queryObj.type = AnType
      this.getPaperList()
    },
    // 切换题目类型
    onClick(val) {
      // console.log(val,777)
      this.$store.state.questionIndex = 0
      this.$store.state.sourceType = this.tabList[val].source
      this.$store.state.page = 1
      this.queryObj.page = 1
      this.queryObj.questionType = 1
      this.queryObj.source = this.tabList[val].source
      this.getPaperList()
    },
    // 获取考试信息以及题目列表
    getPaperList () {
      Toast.loading({
        duration: 0,
        message: "题目生成中",
      })
      this.$request.postHelpCollectOrFail(this.queryObj).then(res => {
        if(res.data.code == 0) {
          this.questionList = res.data.data.records;
          this.remainTime = this.queryObj.type == 1 ? '题目收藏' : '我的错题'
        }
      }).finally(() => {
        Toast.clear()
      })
    },
    // 做题提交
    saveAnswer (question) {
      console.log(question,'qus')
      let obj = question
      if ((question.typeId == 7 || question.typeId == 8) && question.childrenList.length>0) {
        obj = question.childrenList[question.miniNum]
      }
      this.$request.postHelpStudyAnswer({
        answer: obj.answer,
        courseId: obj.courseId,
        parentId: obj.parentId,
        questionId: obj.questionId,
        selfAnswer: obj.selfAnswer,
        typeId: obj.typeId
      }).then((res) => {
        question.trueFlag = res.data.data ? 1 : 0
        if((question.typeId == 7 || question.typeId == 8) && question.childrenList.length>0) {
          question.childrenList[question.miniNum].trueFlag = res.data.data ? 1 : 0
        }
        this.$refs.paper.saveCallback(question, question, 2)
      }).catch((err) => {
        
        });
    },
    // 提交考试
    getSubmitType (data) {
      if (this.queryObj.status == 1 || this.queryObj.status==0) {
        // 考试中
        return this.$request.putHelpStudyExamEnd(data)
      } else if (this.queryObj.status === 2) {
        // 批阅
        return this.$request.putHelpStudyReviewEnd(data)
      }
    },
    submit (val) {
      this.postHelpStudyEnd().then((res) => {
        Notify({ type: 'success', message: '提交成功' })
        const type = this.$store.state.deviceType
        if (val === 'submitSeft') {
          // 主动提交
          this.$refs.paper.submitCallback()
        }
        if (type === 1) {
          window.webkit.messageHandlers.getData.postMessage('close()')
        } else if (type === 2) {
          window.Android.Close()
        }
      }).catch((err) => {
        Notify({ type: 'danger', message: '提交失败' })
      })
    },
    // 批阅改对错
    changeRemarkRead (val) {
      console.log(val,77788)
      let data = {
        courseId: val.courseId,
        isTrue: val.trueFlag == 1 ? 'true' : 'false',
        // paperId: this.queryObj.paperId,
        questionType: val.typeId,
        parentId: val.parentTypeId ? val.parentId : 0,
        questionId: val.questionId,
        selfAnswer: val.selfAnswer,
        source: 2
      }
      // if (this.queryObj.status==2 || ) {
        this.$request.putHelpStudyReviewJudge(data).then((res) => {
          this.$refs.paper.remarkReadCallback(val)
        }).catch((err) => {
        })
      // }
    },
    // 批阅给分
    updateScore (val) {
      let data = {
        paperId: this.queryObj.paperId,
        questionId: val.questionId,
        selfScore: val.selfScore
      }
      if (this.queryObj.status==2) {
        this.$request.putHelpStudyReview(data).then((res) => {
          this.$refs.paper.reviewCallback(val)
        }).catch((err) => {
        })
      }
    },
    // 收藏事件
    handCollect (val) {
      let data = {
        action: 0,
        courseId: val.courseId,
        questionId: val.questionId,
        source: val.source
      }
      console.log(data, 'data')
      this.$request.postHelpStudyQuestionCollect(data).then((res) => {
        Notify({ type: 'success', message: '收藏成功' })
      }).catch((err) => {
        Notify({ type: 'danger', message: '收藏失败' })
      })
    },
    handcollect(val) {
      let keyName = 'paper' + this.active
      // val.source = this.tabList[this.active].source
      val.type = this.queryObj.type
      this.$request.postHelpStudyQuestionCollect(val).then(res => {
        if(res.data.code == 0) {
          Notify({ type: 'success', message: val.action == 1 ? '收藏成功' : '取消收藏' })
          this.$refs[keyName][0].failCollect('success')
        } else {
          Notify({ type: 'success', message: '收藏失败' })
          this.$refs[keyName][0].failCollect('fail')
        }
      })
    },
    changeQuestionType(val) {
      this.queryObj.page = 1
      this.queryObj.questionType = val
      this.getPaperList()
    },
    nextEvent(type) {
      console.log(type)
      // this.queryObj.page = type == 'next' ? ++this.queryObj.page : --this.queryObj.page
      if(type == 'next') {
        if(this.queryObj.page != 1 && this.questionList.length < 30) {
          Toast.loading({
            duration: 1000,
            message: "当前是最后一页了",
          })
          return;
        }
        this.queryObj.page = ++this.queryObj.page
      } else {
        if(this.queryObj.page == 1) {
          Toast.loading({
            duration: 1000,
            message: "当前是第一页了",
          })
          return;
        }
        this.queryObj.page = --this.queryObj.page
      }
      this.$store.state.page = this.queryObj.page
      this.$store.state.questionIndex = 0
      this.getPaperList()
    }
  },
}
</script>

<style>

</style>